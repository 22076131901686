document.addEventListener("DOMContentLoaded", function() {
    // Select all <li> elements with the class 'submenu'
    let submenuItems = document.querySelectorAll('li.submenu');

    // Check if any elements are found
    if (submenuItems.length === 0) {
        console.log("No <li> elements with class 'submenu' found");
    } else {
        // Iterate over each <li> element with class 'submenu'
        submenuItems.forEach(function(item) {
            // Find the <a> tag inside the <li> element
            let link = item.querySelector('a');

            if (link) {
                console.log("Replacing <a> with <strong> in:", item); // Debugging: log each <li> item being processed

                // Create a new <strong> element
                let strong = document.createElement('strong');

                // Copy the content of the <a> tag to the <strong> tag
                strong.textContent = link.textContent;

                // Replace the <a> tag with the <strong> tag
                item.replaceChild(strong, link);
                item.classList.add('light-font')
            } else {
                console.log("No <a> tag found in:", item); // Debugging: log if no <a> tag is found
            }
        });
    }

    let burgerButton = document.querySelector('.burger-button')

    burgerButton.addEventListener('click', function(){
        let burgerButtonSubMenu = document.querySelector('#mobile-nav-menu')
        burgerButtonSubMenu.classList.toggle('display-content')
    })

    let subMenuParentItems = document.querySelectorAll('#header-nav-mobile nav .level_1 li.submenu')
    subMenuParentItems.forEach(function (subMenuParentItem) {
        subMenuParentItem.addEventListener('click', function () {
            let childElement = subMenuParentItem.querySelector('.level_2')
            childElement.classList.toggle('display-content')

        })
    })
});


