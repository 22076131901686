/**
 * SCSS
 */
import './scss/app.scss';
// /**
//  * JS
//  */

import './js/main';
import '../../../node_modules/bootstrap/scss/bootstrap.scss'

import $ from 'jquery';

// Make jQuery globally available
window.$ = $;




